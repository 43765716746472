import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Link } from 'react-router-dom';
import { UserProvider } from './UserContext';
import { supabase } from './supabaseClient';
import Analytics from './services/analytics';
import HomePageHeadshots from './components/HomePage-Headshots';

// Add a loading component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
  </div>
);

// Optimize lazy loading with preload
const preloadComponent = (importFn) => {
  const Component = lazy(importFn);
  Component.preload = importFn;
  return Component;
};

// Lazy load components with preload capability
const Dashboard = preloadComponent(() => import('./components/Dashboard'));
const SignIn = preloadComponent(() => import('./components/SignIn'));
const SignUp = preloadComponent(() => import('./components/SignUp'));
const HomePage = preloadComponent(() => import('./components/HomePage'));
const PrivacyPolicy = preloadComponent(() => import('./components/PrivacyPolicy'));
const NotFound = preloadComponent(() => import('./components/NotFound'));
const TermsAndConditions = preloadComponent(() => import('./components/TermsAndConditions'));
const RefundPolicy = preloadComponent(() => import('./components/RefundPolicy'));

// Create a component to track page views
function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    // Track page view on route change
    Analytics.page({
      path: location.pathname,
      title: document.title
    });
  }, [location]);

  return null;
}

function AuthWrapper() {
  const navigate = useNavigate();

  useEffect(() => {
    const { data: authData } = supabase.auth.getSession();
    if (!authData?.session) {
      const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
        if (event === 'SIGNED_IN') {
          Analytics.track('user_login_success', {
            user_id: session.user.id,
            auth_provider: session.user.app_metadata?.provider || 'email'
          });
          navigate('/dashboard');
        }
      });

      return () => {
        authListener.subscription.unsubscribe();
      };
    }
  }, [navigate]);

  return null;
}

// Add this new component
const SCROLL_OFFSET = -100; // Keep this value the same as in HomePage-Headshots.js

const PricingRedirect = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate('/', { replace: true });
    setTimeout(() => {
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        const offsetTop = pricingSection.getBoundingClientRect().top + window.pageYOffset + SCROLL_OFFSET;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      }
    }, 100);
  }, [navigate]);

  return null;
};

// Add these new redirect components alongside PricingRedirect
const SectionRedirect = ({ sectionId }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate(`/#${sectionId}`, { replace: true });
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        const offsetTop = section.getBoundingClientRect().top + window.pageYOffset + SCROLL_OFFSET;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      }
    }, 100);
  }, [navigate, sectionId]);

  return null;
};

// Add preloading on hover/focus of navigation elements
const PreloadLink = ({ to, children }) => {
  const handlePreload = () => {
    const route = routes[to];
    if (route?.component?.preload) {
      route.component.preload();
    }
  };

  return (
    <Link 
      to={to} 
      onMouseEnter={handlePreload}
      onFocus={handlePreload}
    >
      {children}
    </Link>
  );
};

// Define routes configuration
const routes = {
  '/dashboard': { component: Dashboard },
  '/signin': { component: SignIn },
  '/signup': { component: SignUp },
  // Add other routes as needed
};

function App() {
  return (
    <Router>
      <UserProvider>
        <AuthWrapper />
        <PageTracker />
        <Routes>
          <Route 
            path="/" 
            element={<HomePageHeadshots />} 
          />
          
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Dashboard />
              </Suspense>
            }
          />
          
          <Route
            path="/signin"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <SignIn />
              </Suspense>
            }
          />

          <Route
            path="/signup"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <SignUp />
              </Suspense>
            }
          />

          <Route
            path="/privacy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />

          <Route
            path="/terms"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <TermsAndConditions />
              </Suspense>
            }
          />

          <Route
            path="/refund-policy"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <RefundPolicy />
              </Suspense>
            }
          />

          <Route path="/pricing" element={<PricingRedirect />} />
          <Route path="/how-it-works" element={<SectionRedirect sectionId="how-it-works" />} />
          <Route path="/why-choose-us" element={<SectionRedirect sectionId="why-choose-us" />} />
          <Route path="/reviews" element={<SectionRedirect sectionId="reviews" />} />

          <Route
            path="*"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <NotFound />
              </Suspense>
            }
          />
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;