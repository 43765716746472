import React, { lazy, Suspense } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Make sure to import supabase client
import { motion, useScroll, useTransform } from 'framer-motion'; // Add this import
import Masonry from 'react-masonry-css'; // Add this import
import { 
  FaChevronDown, 
  FaInstagram, 
  FaSlack, 
  FaStar, 
  FaClock, 
  FaImages, 
  FaPalette, 
  FaDollarSign, 
  FaBolt, 
  FaPaintBrush, 
  FaTshirt, 
  FaImage, 
  FaRedoAlt, 
  FaCameraRetro,
  FaCheck,
  FaChevronLeft,
  FaChevronRight
} from 'react-icons/fa';
import { BsChatDots } from "react-icons/bs";
import { createGlobalStyle } from 'styled-components';
import { useWindowSize } from '../hooks/useWindowSize'; // Add this import
import { useMediaQuery } from 'react-responsive'; // Add this import
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { RiSparklingFill } from 'react-icons/ri';
import { IoCheckmarkCircle, IoCloseCircle, IoInfiniteSharp } from "react-icons/io5";
import { MdOutlineStyle, MdPhotoFilter, MdAutoAwesome } from "react-icons/md";
import { GiClothes } from "react-icons/gi";
import { BsLightningChargeFill } from "react-icons/bs";
import { AnimatePresence } from 'framer-motion';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');


  body {
    overflow-x: hidden;
  }
`;

const HomePageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;
  background: linear-gradient(to bottom right, #000000, #111111);
`;

const CarouselWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const CinematicOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7) 0%,  // Reduced from 0.7
    rgba(0, 0, 0, 0.5) 50%, // Reduced from 0.5
    rgba(0, 0, 0, 0.3) 100% // Reduced from 0.3
  );
  z-index: 2;
`;

const StaticContent = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 60%;
  text-align: center;
  color: white;
  z-index: 3;
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
  padding: 2rem;
  will-change: transform;

  @media (max-width: 768px) {
    width: 90%;
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-family: 'Circular', 'Nunito', sans-serif;
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #F8F0E3;
  will-change: transform;
  content: 'Turn Selfies Into Stunning AI Headshots';
  
  @font-face {
    font-family: 'Circular';
    font-display: swap;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
    margin-bottom: 0.75rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  opacity: 0.9;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  color: #E0E0E0;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
    gap: 0.5rem;
  }

  @media (max-width: 480px) {
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
  }
`;

const IconText = styled.span`
  display: inline-flex;
  align-items: center;
  margin: 0 10px;
  font-weight: bold;
  font-size: 1.6rem;
  color: #F8F0E3;
  font-family: 'Lato', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin: 0;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const BackgroundImage = styled.div`
  height: 100vh;
  background-size: cover;
  background-position: center;
  filter: brightness(0.7) contrast(1.1);
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const Button = styled(Link)`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const GetStartedButton = styled(Button)`
  background-color: #D4AF37;
  color: #1A1A1A;
  box-shadow: 0 4px 6px rgba(212, 175, 55, 0.3);
  font-size: 1.2rem;
  padding: 1rem 2rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  
  &:hover {
    background-color: #E5C100;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(212, 175, 55, 0.4);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.875rem 1.75rem;
  }

  @media (max-width: 480px) {
    width: 100%;
    max-width: 280px;
  }
`;

const GoogleLoginButton = styled(Button)`
  background-color: #4285F4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  
  &:hover {
    background-color: #357AE8;
    transform: translateY(-2px);
  }
`;

const SeePlansButton = styled(Button)`
  background-color: transparent;
  color: white;
  border: 2px solid white;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
  }
`;

const TopRightButtons = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 3;

  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
    gap: 8px;
  }
`;

const TopButton = styled(Link)`
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media (max-width: 768px) {
    padding: 0.4rem 0.875rem;
    font-size: 0.8rem;
  }
`;

const LoginButton = styled(TopButton)`
  background-color: transparent;
  color: white;
  border: 1px solid white;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    display: none; // Hide login button on mobile
  }
`;

const SignupButton = styled(TopButton)`
  background-color: white;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const SecondFold = styled.div`
  background-color: #f0f4f8;
  padding: 4rem 0;
  overflow: hidden;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MasonryGridWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
`;

const MasonryGrid = styled(Masonry)`
  display: flex;
  margin-left: -1rem;
  width: calc(100% + 1rem);
  overflow: hidden;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }
`;

const MasonryColumn = styled.div`
  padding-left: 1.5rem;
  background-clip: padding-box;
`;

const TemplateCard = styled(motion.div)`
  display: inline-block;
  width: calc(100% - 1rem);
  margin-bottom: 1.5rem;
  margin-right: 1rem;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  aspect-ratio: 3 / 4;
  box-sizing: border-box;
  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const TemplateImage = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
`;

const TemplateInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  color: white;
`;

const TemplateTopInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
  color: white;
`;

const TemplateTitle = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`;

const TemplateCategory = styled.span`
  font-size: 0.8rem;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.2rem 0.5rem;
  border-radius: 12px;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
`;

const SearchAndFilter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const TopLeftNav = styled.nav`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  z-index: 3;

  @media (max-width: 768px) {
    top: 15px;
    left: 15px;
  }
`;

const NavLinkStyled = styled.a`
  color: white;
  text-decoration: none;
  margin-left: 20px;
  font-weight: normal;
  font-size: 1rem;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #D4AF37;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-left: 12px;
  }
`;


const SearchInput = styled.input`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 25px;
  width: 300px;
  margin-right: 1rem;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ScrollPrompt = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 3;

  @media (max-width: 768px) {
    bottom: 15px;
  }
`;

const ScrollText = styled.span`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ScrollArrow = styled(FaChevronDown)`
  font-size: 1.5rem;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
`;

const FloatingText = styled.div`
  position: fixed;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  transition: opacity 0.2s ease-in-out;
`;

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  @media (max-width: 480px) {
    width: 28px;
    height: 28px;
    margin-right: 6px;
  }
`;

const StyledSlack = styled(FaSlack)`
  color: #F8F0E3;
  font-size: 22px;
`;

const StyledLinkedIn = styled(FaInstagram)`
  color: #F8F0E3;
  font-size: 22px;
`;

const StyledChat = styled(BsChatDots)`
  color: #F8F0E3;
  font-size: 22px;
`;

const FunHeader = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
  letter-spacing: -0.5px;
  line-height: 1.2;
`;

const TopLeftLogo = styled.img`
  height: 60px;
  margin-right: 20px;

  @media (max-width: 768px) {
    height: 40px;
    margin-right: 12px;
  }
`;

// Update Footer styling
const Footer = styled.footer`
  background: linear-gradient(180deg, #f8f9ff 0%, #ffffff 100%);
  padding: 6rem 2rem 3rem;  // Changed from original padding
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, rgba(0, 102, 255, 0.2), transparent);
  }

  @media (max-width: 768px) {
    padding: 3rem 1rem 2rem;  // Changed from original padding
  }
`;

// Update FooterContent styling
const FooterContent = styled.div`
  max-width: 1200px;  // Changed from 1400px to match other sections
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 6rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

// Update FooterSection styling
const FooterSection = styled.div`
  &:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    &:first-child, &:last-child {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
`;

// Update FooterLogo styling
const FooterLogo = styled.img`
  height: 50px;
  width: auto;
  margin-bottom: 1rem;
  object-fit: contain;

  @media (max-width: 768px) {
    height: 40px;
    margin-bottom: 0.5rem;
  }
`;

// Update NavigationLinks styling
const NavigationLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
`;

// Update NavLink styling
const NavLink = styled(Link)`
  color: #555;
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.3s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 1px;
    background: #0066FF;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #0066FF;
    
    &::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    text-align: center;
  }
`;

// Update FooterBottom styling
const FooterBottom = styled.div`
  margin-top: 3rem;
  padding: 2rem 0;  // Changed padding
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 0.9rem;
  max-width: 1200px;  // Added max-width to match other sections
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    margin-top: 2rem;
    padding: 1.5rem 1rem;  // Added horizontal padding for mobile
    font-size: 0.8rem;
  }
`;

// Update SocialLinks styling
const SocialLinks = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
    justify-content: center;
    margin-top: 0.25rem;
  }
`;

// Update SocialIcon styling
const SocialIcon = styled.a`
  color: #555;
  font-size: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    color: #0066FF;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

// Lazy load sections
const HowItWorksFold = lazy(() => import('./sections/HowItWorksFold'));
const ComparisonFold = lazy(() => import('./sections/ComparisonFold'));
const PricingFold = lazy(() => import('./sections/PricingFold'));
const ReviewsFold = lazy(() => import('./sections/ReviewsFold'));
const UseCasesFold = lazy(() => import('./sections/UseCasesFold'));

// Add a simple loading component
const SectionLoader = () => (
  <div style={{ height: '40vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div className="loading-spinner"></div>
  </div>
);

// Add slides data
const slides = [
  {
    image: 'https://emmpsnolvsyhrljwqtwq.supabase.co/storage/v1/object/public/product-assets/headshots-fold1-2.webp',
    fallback: 'https://emmpsnolvsyhrljwqtwq.supabase.co/storage/v1/object/public/product-assets/headshots-fold1-2.jpg',
  },
  {
    image: 'https://emmpsnolvsyhrljwqtwq.supabase.co/storage/v1/object/public/product-assets/headshots-fold1-5.webp',
    fallback: 'https://emmpsnolvsyhrljwqtwq.supabase.co/storage/v1/object/public/product-assets/headshots-fold1-5.jpg',
  },
  {
    image: 'https://emmpsnolvsyhrljwqtwq.supabase.co/storage/v1/object/public/product-assets/headshots-fold1-3.webp',
    fallback: 'https://emmpsnolvsyhrljwqtwq.supabase.co/storage/v1/object/public/product-assets/headshots-fold1-3.jpg',
  },
];

// Near the top of the file, add this new component
const OptimizedImage = React.memo(({ src, fallback, alt, loading, fetchPriority }) => {
  return (
    <picture>
      <source srcSet={src} type="image/webp" />
      <img
        src={fallback}
        alt={alt}
        loading={loading}
        fetchPriority={fetchPriority}
        decoding="async"
      />
    </picture>
  );
});

// Update the carousel implementation
const CarouselSlides = React.memo(({ slides }) => {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      interval={1500}
      showStatus={false}
      showThumbs={false}
      showArrows={false}
      showIndicators={false}
      stopOnHover={false}
      swipeable={false}
      dynamicHeight={false}
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
    >
      {slides.map((slide, index) => (
        <div key={index}>
          <BackgroundImage>
            <OptimizedImage
              src={slide.image}
              fallback={slide.fallback}
              alt="Hero background"
              loading={index === 0 ? "eager" : "lazy"}
              fetchPriority={index === 0 ? "high" : "auto"}
            />
          </BackgroundImage>
        </div>
      ))}
    </Carousel>
  );
});

// Add intersection observer for scroll animations
const useIntersectionObserver = (options = {}) => {
  const [isIntersecting, setIntersecting] = React.useState(false);
  const ref = React.useRef();

  React.useEffect(() => {
    if (!window.IntersectionObserver) {
      setIntersecting(true);
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIntersecting(true);
        // Disconnect after first intersection
        observer.disconnect();
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return [ref, isIntersecting];
};

// Add this constant near the top of the file
const SCROLL_OFFSET = -100; // Adjust this value as needed

// Update the scrollToSection function
const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    const offsetTop = section.getBoundingClientRect().top + window.pageYOffset + SCROLL_OFFSET;
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth'
    });
  }
};

// Update the HomePage component
const HomePage = () => {
  // Add floatingText state
  const [floatingText, setFloatingText] = React.useState({ show: false, x: 0, y: 0 });
  
  // Memoize the slides data
  const memoizedSlides = React.useMemo(() => slides, []);
  
  // Defer non-critical operations
  React.useEffect(() => {
    let isAnalyticsLoaded = false;
    
    const loadAnalytics = async () => {
      if (!isAnalyticsLoaded) {
        isAnalyticsLoaded = true;
        const Analytics = (await import('../services/analytics')).default;
        Analytics.page({
          path: '/',
          title: document.title
        });
      }
    };

    // Load analytics after user interaction or 5 seconds
    const timer = setTimeout(loadAnalytics, 5000);

    // Load analytics on scroll
    const handleScroll = () => {
      clearTimeout(timer);
      loadAnalytics();
      window.removeEventListener('scroll', handleScroll);
    };

    // Load analytics on click
    const handleClick = () => {
      clearTimeout(timer);
      loadAnalytics();
      window.removeEventListener('click', handleClick);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('click', handleClick);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('click', handleClick);
    };
  }, []);

  // Use intersection observer for scroll animations
  const [contentRef, isContentVisible] = useIntersectionObserver({
    threshold: 0.1,
    rootMargin: '50px'
  });

  // Add handleMouseMove for floating text
  const handleMouseMove = React.useCallback((e) => {
    if (e.target.closest('a')) {
      setFloatingText({
        show: true,
        x: e.clientX + 10,
        y: e.clientY + 10
      });
    } else {
      setFloatingText(prev => ({ ...prev, show: false }));
    }
  }, []);

  // Add event listener for mouse move
  React.useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [handleMouseMove]);

  return (
    <>
      <GlobalStyle />
      <HomePageWrapper>
        <TopLeftNav>
          <TopLeftLogo 
            src={"https://emmpsnolvsyhrljwqtwq.supabase.co/storage/v1/object/public/product-assets/pai-logo-white.png"} 
            alt="Logo" 
            loading="eager"
            fetchPriority="high"
          />
          <NavLinkStyled 
            href="#pricing" 
            onClick={(e) => {
              e.preventDefault();
              scrollToSection('pricing');
            }}
          >
            Pricing
          </NavLinkStyled>
        </TopLeftNav>
        
        <TopRightButtons>
          <LoginButton to="/signin">Login</LoginButton>
          <SignupButton to="/signup">Sign Up</SignupButton>
        </TopRightButtons>
        
        <CarouselWrapper>
          <CarouselSlides slides={memoizedSlides} />
        </CarouselWrapper>
        
        <CinematicOverlay />
        
        <StaticContent ref={contentRef}>
          <Title>Turn Selfies Into Stunning AI Headshots</Title>
          
          {isContentVisible && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <IconContainer>
                <IconText>
                  <IconWrapper><StyledSlack /></IconWrapper>Slack
                </IconText>
                <IconText>
                  <IconWrapper><StyledLinkedIn /></IconWrapper>LinkedIn
                </IconText>
                <IconText>
                  <IconWrapper><StyledChat /></IconWrapper>Chat
                </IconText>
              </IconContainer>
              <ButtonContainer>
                <GetStartedButton to="/signup">Try it now</GetStartedButton>
              </ButtonContainer>
            </motion.div>
          )}
        </StaticContent>

        <ScrollPrompt>
          <ScrollText>See how it works</ScrollText>
          <ScrollArrow />
        </ScrollPrompt>
      </HomePageWrapper>

      {/* Wrap sections in Suspense */}
      <Suspense fallback={<SectionLoader />}>
        <HowItWorksFold />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <ComparisonFold />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <PricingFold />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <ReviewsFold />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <UseCasesFold />
      </Suspense>

      {/* Keep the footer as is since it's important for SEO */}
      <Footer>
        <FooterContent>
          <FooterSection>
            <FooterLogo 
              src="https://emmpsnolvsyhrljwqtwq.supabase.co/storage/v1/object/public/product-assets/pai-logo-black.png" 
              alt="Photographer AI Logo" 
            />
          </FooterSection>

          <FooterSection>
            <NavigationLinks>
              <NavLink to="/dashboard">Dashboard</NavLink>
              <NavLink to="/pricing">Pricing</NavLink>
              <NavLink to="/signin">Sign In</NavLink>
              <NavLink to="/signup">Sign Up</NavLink>
              <NavLink to="/privacy">Privacy Policy</NavLink>
              <NavLink to="/terms">Terms of Service</NavLink>
              <NavLink to="/refund-policy">Refund Policy</NavLink>
              <NavLink to="#" onClick={(e) => {
                e.preventDefault();
                window.location.href = 'mailto:support@photographer-ai.com';
              }}>Contact</NavLink>
            </NavigationLinks>
          </FooterSection>
        </FooterContent>

        <FooterBottom>
          <div>© {new Date().getFullYear()} Photographer AI. All rights reserved.</div>
          <SocialLinks>
            <SocialIcon href="https://www.instagram.com/_photographer_ai" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialIcon>
          </SocialLinks>
        </FooterBottom>
      </Footer>
    </>
  );
};

// Add display name for memo components
OptimizedImage.displayName = 'OptimizedImage';
CarouselSlides.displayName = 'CarouselSlides';

export default React.memo(HomePage);