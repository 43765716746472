// Analytics service to handle PostHog, Meta Pixel, and Google Analytics

const EVENT_MAPPING = {
  // Authentication Events
  user_signup_started: {
    meta: 'InitiateRegistration',
    google: 'begin_sign_up'
  },
  user_signup_completed: {
    meta: 'CompleteRegistration',
    google: 'sign_up'
  },
  user_login_success: {
    meta: 'Login',
    google: 'login'
  },
  user_login_failed: {
    meta: 'LoginFailed',
    google: 'login_failed'
  },
  user_logout: {
    meta: 'Logout',
    google: 'logout'
  },
  password_reset_requested: {
    meta: 'InitiatePasswordReset',
    google: 'password_reset'
  },

  // Upload Flow Events
  photo_upload_started: {
    meta: 'InitiateUpload',
    google: 'upload_start'
  },
  photo_upload_completed: {
    meta: 'CompleteUpload',
    google: 'upload_complete'
  },
  photo_upload_failed: {
    meta: 'UploadFailed',
    google: 'upload_failed'
  },
  photo_deleted: {
    meta: 'DeleteContent',
    google: 'content_delete'
  },
  upload_section_completed: {
    meta: 'CompleteStep',
    google: 'upload_step_complete'
  },
  style_selection_started: {
    meta: 'InitiateCustomization',
    google: 'begin_customization'
  },
  style_selection_completed: {
    meta: 'CompleteCustomization',
    google: 'complete_customization'
  },

  // Training Events
  training_started: {
    meta: 'InitiateTraining',
    google: 'begin_training'
  },
  training_completed: {
    meta: 'CompleteTraining',
    google: 'complete_training'
  },
  training_failed: {
    meta: 'TrainingFailed',
    google: 'training_failed'
  },

  // Generation Events
  generation_started: {
    meta: 'InitiateGeneration',
    google: 'begin_generation'
  },
  generation_completed: {
    meta: 'CompleteGeneration',
    google: 'complete_generation'
  },
  generation_failed: {
    meta: 'GenerationFailed',
    google: 'generation_failed'
  },
  prompt_submitted: {
    meta: 'SubmitPrompt',
    google: 'prompt_submission'
  },
  image_downloaded: {
    meta: 'Download',
    google: 'file_download'
  },
  image_shared: {
    meta: 'Share',
    google: 'share'
  },

  // Pricing/Payment Events
  pricing_page_viewed: {
    meta: 'ViewPricing',
    google: 'view_pricing'
  },
  plan_selected: {
    meta: 'SelectPlan',
    google: 'select_plan'
  },
  checkout_started: {
    meta: 'InitiateCheckout',
    google: 'begin_checkout',
    tiktok: 'InitiateCheckout'
  },
  checkout_completed: {
    meta: 'Purchase',
    google: 'purchase',
    tiktok: 'CompletePayment'
  },
  checkout_failed: {
    meta: 'PaymentFailed',
    google: 'payment_failed'
  },
  subscription_cancelled: {
    meta: 'CancelSubscription',
    google: 'subscription_cancel'
  },
  plan_purchased: {
    meta: 'Purchase',
    google: 'purchase'
  },

  // Navigation/UI Events
  page_viewed: {
    meta: 'PageView',
    google: 'page_view'
  },
  modal_opened: {
    meta: 'OpenModal',
    google: 'modal_open'
  },
  modal_closed: {
    meta: 'CloseModal',
    google: 'modal_close'
  },
  billing_drawer_opened: {
    meta: 'OpenBilling',
    google: 'billing_open'
  },
  billing_drawer_closed: {
    meta: 'CloseBilling',
    google: 'billing_close'
  },

  // Feature Usage Events
  credits_checked: {
    meta: 'CheckCredits',
    google: 'view_credits'
  },
  gallery_filtered: {
    meta: 'FilterContent',
    google: 'content_filter'
  },
  image_previewed: {
    meta: 'ViewContent',
    google: 'content_view'
  },
  help_documentation_accessed: {
    meta: 'ViewHelp',
    google: 'help_view'
  },

  // Error Events
  validation_error: {
    meta: 'ValidationError',
    google: 'validation_error'
  },
  api_error: {
    meta: 'APIError',
    google: 'api_error'
  },
  upload_error: {
    meta: 'UploadError',
    google: 'upload_error'
  },
  insufficient_credits_warning: {
    meta: 'InsufficientCredits',
    google: 'credits_warning'
  },

  // Legal/Policy Page Events
  policy_page_viewed: {
    meta: 'ViewContent',
    google: 'policy_view'
  },
  policy_link_clicked: {
    meta: 'ClickContent',
    google: 'policy_click'
  }
};

// Add standard properties that should be included with every event
const getStandardProperties = () => ({
  timestamp: new Date().toISOString(),
  url: window.location.href,
  path: window.location.pathname,
  referrer: document.referrer,
  device_type: getDeviceType(),
  browser_info: getBrowserInfo()
});

// Helper function to get device type
const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};

// Helper function to get browser info
const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  const browserName = navigator.appName;
  const browserVersion = navigator.appVersion;
  return {
    userAgent: ua,
    browser: browserName,
    version: browserVersion
  };
};

// Add SHA-256 hashing function for email
const sha256 = async (text) => {
  const msgBuffer = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

// Replace the existing PostHog initialization with this lazy loading approach
let posthog = null;

const initPostHog = async () => {
  if (!posthog) {
    try {
      const PostHog = await import('posthog-js');
      posthog = PostHog.default;
      posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
        api_host: process.env.REACT_APP_POSTHOG_HOST || 'https://app.posthog.com',
        loaded: (ph) => {
          if (process.env.NODE_ENV === 'development') {
            ph.opt_out_capturing();
          }
        },
        autocapture: false,
        capture_pageview: false,
        persistence: 'localStorage'
      });
    } catch (error) {
      console.warn('PostHog failed to load:', error);
      // Return a dummy posthog object that does nothing
      return {
        capture: () => {},
        identify: () => {}
      };
    }
  }
  return posthog;
};

const Analytics = {
  page: async ({ path, title }) => {
    const ph = await initPostHog();
    if (ph) {
      ph.capture('$pageview', {
        $current_url: path,
        page_title: title
      });
    }
  },

  track: async (event, properties) => {
    // First push to PostHog
    const ph = await initPostHog();
    if (ph) {
      ph.capture(event, properties);
    }

    // Get the mapped event names
    const mappedEvent = EVENT_MAPPING[event] || {};
    
    // Push to dataLayer for GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,  // original event name
      eventCategory: properties.category || 'All',
      eventAction: properties.action || event,
      eventLabel: properties.label || '',
      eventValue: properties.value || 0,
      ...properties  // include all other properties
    });

    // If it's a purchase event, include ecommerce data
    if (event === 'checkout_completed' || event === 'purchase') {
      window.dataLayer.push({
        ecommerce: {
          currency: properties.currency || 'USD',
          value: properties.value,
          items: properties.items || []
        }
      });
    }
  },

  identify: async (id, traits) => {
    const ph = await initPostHog();
    if (ph) {
      ph.identify(id, traits);
    }
  }
};

export default Analytics; 